export function borgerDk() {
  if (jQuery(".js-borgerdk-accordion").length > 0) {
    CheckArticle();
    updateLinks();
  }
}

function CheckArticle(){
  var accordion = jQuery(".js-borgerdk-accordion");
  var pageId = accordion.data("pageid");
  var articleId = accordion.data("articleid");
  var lastupdated = accordion.data("lastupdated");
  var url = "/umbraco/api/BorgerDkArticle/CheckForUpdates?articleId=" + articleId + "&date=" + lastupdated + "&pageId=" + pageId + "&municipalityCode=147";

  $.ajax({
    url: url,
    contentType: "application/json",
    type: 'GET',
    dataType: 'json',
    data: {
    },
    error: function (data) {
      // eslint-disable-next-line no-console
      console.log("Error: ", data)
    },
    success: function (data) {
    }
  });
}

function updateLinks() {
  jQuery(".borgerdk--accordion").find("a").each(function () {
    var href = jQuery(this).attr("href");
    if (href.indexOf("borger.dk") > -1) {
      jQuery(this).attr("target", "_blank");
    }
  });
}
export default borgerDk;
