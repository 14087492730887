// Change styles

// Style burger menu

jQuery(document).on("click", '.style-burger', function() {
  jQuery(this).toggleClass('active');

  if (jQuery('.style-burger').hasClass('active')) {
    jQuery('.style-menu').addClass('show');
    var styleMenuWidth = jQuery('.style-menu').outerWidth();
    jQuery('.style-burger').css("right", styleMenuWidth + 15);
  } else {
    jQuery('.style-menu').removeClass('show');
    jQuery('.style-burger').css("right", "15px");
  }
});

// Dropdowns with active state
jQuery(document).on("click", '.style-menu .dropdown-menu button', function() {
  var thisParent = jQuery(this).parents('.dropdown-menu');
  var listItem = jQuery(thisParent).find('li')

  jQuery(listItem).each(function (i, item) {
    var itemChild = jQuery(item).children('button');
    jQuery(itemChild).removeClass('active');
  });

  jQuery(this).addClass('active');
});

// Change list-arrows
jQuery(document).on("click", '.change--arrow-icons__list-icons button', function() {
  removeListarrowClass();
  var getClass = jQuery(this).attr('data-class');
  jQuery('body').addClass(getClass);
});

function removeListarrowClass() {
  if(jQuery('body').is('[class*="list-icon-"]')) {
    jQuery("body").removeClass (function (index, className) {
      return (className.match (/(^|\s)list-icon-\S+/g) || []).join(' ');
    });
  }
}

// Change CTA-arrows
jQuery(document).on("click", '.change--arrow-icons__cta-icons button', function() {
  removeCtaarrowClass();
  var getClass = jQuery(this).attr('data-class');
  jQuery('body').addClass(getClass);
});

function removeCtaarrowClass() {
  if(jQuery('body').is('[class*="cta-icon-"]')) {
    jQuery("body").removeClass (function (index, className) {
      return (className.match (/(^|\s)cta-icon-\S+/g) || []).join(' ');
    });
  }
}

// Change link animations
jQuery(document).on("click", '.change--link-animations .dropdown-menu button', function() {
  removeLinkanimationClass();
  var getClass = jQuery(this).attr('data-class');
  jQuery('body').addClass(getClass);
});

function removeLinkanimationClass() {
  if(jQuery('body').is('[class*="link--"]')) {
    jQuery("body").removeClass (function (index, className) {
      return (className.match (/(^|\s)link--\S+/g) || []).join(' ');
    });
  }
}

// Change link animations: component titles
jQuery(document).on("click", '.change--link-animations--c-titles .dropdown-menu button', function() {
  removeLinkanimationCTitlesClass();
  var getClass = jQuery(this).attr('data-class');
  jQuery('body').addClass(getClass);
});

function removeLinkanimationCTitlesClass() {
  if(jQuery('body').is('[class*="c-title--link--"]')) {
    jQuery("body").removeClass (function (index, className) {
      return (className.match (/(^|\s)link--\S+/g) || []).join(' ');
    });
  }
}

// Change font-size base
jQuery(".change--text-styling [data-var]").bind('keyup mouseup', function () {
  var value = jQuery(this).val();

  if (jQuery(this).data('var') === "--base-font-size") {
    document.documentElement.style.setProperty('--base-font-size', value + 'px');
  }

  if (jQuery(this).data('var') === "--heading-font-weight") {
    document.documentElement.style.setProperty('--heading-font-weight', value);
  }

  if (jQuery(this).data('var') === "--body-font-weight") {
    document.documentElement.style.setProperty('--body-font-weight', value);
  }

  if (jQuery(this).data('var') === "--tag-font-weight") {
    document.documentElement.style.setProperty('--tag-font-weight', value);
  }

  if (jQuery(this).data('var') === "--heading-menu-font-weight") {
    document.documentElement.style.setProperty('--heading-menu-font-weight', value);
  }

  if (jQuery(this).data('var') === "--h1-font-size-lg") {
    document.documentElement.style.setProperty('--h1-font-size-lg', value);
  }

  if (jQuery(this).data('var') === "--h1-font-size-sm") {
    document.documentElement.style.setProperty('--h1-font-size-sm', value);
  }

  if (jQuery(this).data('var') === "--h2-font-size-lg") {
    document.documentElement.style.setProperty('--h2-font-size-lg', value);
  }

  if (jQuery(this).data('var') === "--h2-font-size-sm") {
    document.documentElement.style.setProperty('--h2-font-size-sm', value);
  }

  if (jQuery(this).data('var') === "--h3-font-size-lg") {
    document.documentElement.style.setProperty('--h3-font-size-lg', value);
  }

  if (jQuery(this).data('var') === "--h3-font-size-sm") {
    document.documentElement.style.setProperty('--h3-font-size-sm', value);
  }

  if (jQuery(this).data('var') === "--h4-font-size-lg") {
    document.documentElement.style.setProperty('--h4-font-size-lg', value);
  }

  if (jQuery(this).data('var') === "--h4-font-size-sm") {
    document.documentElement.style.setProperty('--h4-font-size-sm', value);
  }

  if (jQuery(this).data('var') === "--small-font-size") {
    document.documentElement.style.setProperty('--small-font-size', value);
  }

  if (jQuery(this).data('var') === "--small-details-font-size") {
    document.documentElement.style.setProperty('--small-details-font-size', value);
  }

  if (jQuery(this).data('var') === "--btn-font-size") {
    document.documentElement.style.setProperty('--btn-font-size', value);
  }

  if (jQuery(this).data('var') === "--btn-link-font-size") {
    document.documentElement.style.setProperty('--btn-link-font-size', value);
  }

  if (jQuery(this).data('var') === "--form-font-size") {
    document.documentElement.style.setProperty('--form-font-size', value);
  }

  if (jQuery(this).data('var') === "--breadcrumb-font-size") {
    document.documentElement.style.setProperty('--breadcrumb-font-size', value);
  }

  if (jQuery(this).data('var') === "--component-body-font-size") {
    document.documentElement.style.setProperty('--component-body-font-size', value);
  }

  if (jQuery(this).data('var') === "--article-subheading-font-size-lg") {
    document.documentElement.style.setProperty('--article-subheading-font-size-lg', value);
  }

  if (jQuery(this).data('var') === "--article-subheading-font-size-sm") {
    document.documentElement.style.setProperty('--article-subheading-font-size-sm', value);
  }

  if (jQuery(this).data('var') === "--article-body-font-size-lg") {
    document.documentElement.style.setProperty('--article-body-font-size-lg', value);
  }

  if (jQuery(this).data('var') === "--article-body-font-size-sm") {
    document.documentElement.style.setProperty('--article-body-font-size-sm', value);
  }

  if (jQuery(this).data('var') === "--header-nav-link-font-size") {
    document.documentElement.style.setProperty('--header-nav-link-font-size', value);
  }

  if (jQuery(this).data('var') === "--megamenu-link-list-font-size") {
    document.documentElement.style.setProperty('--megamenu-link-list-font-size', value);
  }

  if (jQuery(this).data('var') === "--font-primary-line-height") {
    document.documentElement.style.setProperty('--font-primary-line-height', value);
  }

  if (jQuery(this).data('var') === "--font-secondary-line-height") {
    document.documentElement.style.setProperty('--font-secondary-line-height', value);
  }

  if (jQuery(this).data('var') === "--article-body-line-height") {
    document.documentElement.style.setProperty('--article-body-line-height', value);
  }

});
